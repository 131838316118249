/* eslint-disable no-undef */
<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    bottom
    left
    max-width="90%"
    offset-x
    offset-y
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-card
        id="settings"
        class="py-2 px-4"
        color="#0000004D"
        dark
        flat
        style="position: fixed; top: 164px; right: -35px"
        width="100"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon x-large color="orange">mdi-calculator</v-icon>
      </v-card>
    </template>

    <template>
      <v-card color="basil">
        <v-card-title class="text-center justify-center py-6">
          <h1 class="font-weight-bold display-1 basil--text">
            Loan Calculator
          </h1>
        </v-card-title>

        <v-card-text>
          <v-row no-gutters>
            <v-col>
              <v-card class="mx-auto" outlined>
                <v-list-item>
                  <v-list-item-content class="justify-center">
                    <v-radio-group v-model="userType" row>
                      <v-radio
                        v-for="type in USER_TYPES"
                        :key="type.value"
                        :label="type.text"
                        :value="type.value"
                      ></v-radio>
                    </v-radio-group>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>

            <v-col>
              <v-card class="mx-auto" outlined>
                <v-list-item>
                  <v-list-item-content class="justify-center">
                    <v-radio-group v-model="loanType" row>
                      <v-radio
                        v-for="item in loanTypes"
                        :key="item.value"
                        :label="item.text"
                        :value="item.value"
                      ></v-radio>
                    </v-radio-group>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>

          <v-list-item>
            <v-list-item-content>
              <v-slider
                v-model="loanRequired"
                :min="loanMin"
                step="1000"
                :max="loanMax"
                label="Amount"
              >
                <template v-slot:append>
                  <span>
                    {{ loanRequired | toCurrency }}
                  </span>
                </template>
              </v-slider>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-slider
                v-model="days"
                :min="minDays"
                :step="1"
                :max="maxDays"
                label="Days"
              >
                <template v-slot:append>
                  <span>
                    {{ days }}
                  </span>
                </template>
              </v-slider>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-row no-gutters>
                <v-col cols="3">
                  <v-text-field
                    label="Installment"
                    readonly
                    outlined
                    :value="results.Installment | toCurrency"
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    label="Total Interest"
                    readonly
                    outlined
                    :value="results.TotalInterest | toCurrency"
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    label="Total Payback"
                    readonly
                    outlined
                    :value="results.totalPayback | toCurrency"
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    label="No of Paybacks"
                    readonly
                    outlined
                    :value="terms"
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    :label="interestRateLabel"
                    readonly
                    outlined
                    :value="results.InterestRate | formatPercent"
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    label="Processing Fees"
                    readonly
                    outlined
                    :value="results.ProcessingFees | toCurrency"
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    label="Daily Interest"
                    readonly
                    outlined
                    :value="results.DailyInterest | toCurrency"
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    label="Effective Annual Interest Rate"
                    readonly
                    success
                    outlined
                    :value="effectiveAnnualInterestRate"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>

          <v-alert v-show="!!warningAlert.message" :type="warningAlert.type">{{
            warningAlert.message
          }}</v-alert>
          <br />
          <br />
          <br />
          <br />
        </v-card-text>
      </v-card> </template
  ></v-menu>
</template>

<script>
import settingMixins from "@/mixins/settingMixins";
import graphqlMixin from "@/mixins/graphql-mixin.js";

export default {
  name: "HomeCalculator",
  mixins: [settingMixins, graphqlMixin],
  data() {
    return {
      menu: false,
    };
  },
  filters: {
    formatPercent(value) {
      return `${value}%`;
    },
    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },

  computed: {
    warningAlert() {
      if (this.userType == "BPO_BASIC" && this.loanType === "Term")
        return {
          type: "warning",
          message: `All agents are fixed to “payday loan” until 6 transactions are completed. 
              Once this requirement is met agent may send a message to admin to apply for a term loan`,
        };
      else if (this.userType == "NON_BPO" && this.loanType === "Term")
        return {
          type: "warning",
          message: `BiWeekly Only`,
        };
      else
        return {
          type: "info",
          message: ``,
        };
    },
  },
  methods:{
    
  }
};
</script>
<style scoped>
:disabled {
  color: #000000 !important;
}
td {
  text-align: right;
}
</style>
